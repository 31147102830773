import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "@styles/nav.scss"
// const isActive = ({ isCurrent }) => {
//   return isCurrent
//     ? { className: "nav-link active" }
//     : { className: "nav-link" }
// }

// const ExactNavLink = props => <Link getProps={isActive} {...props} />

const Navbar = ({ siteTitle }) => {
  return (
    <nav className="navbar-custom">
      <div className="container">
        <div className="d-md-flex">
          <div className="col-md-6 d-flex justify-content-center justify-content-center justify-content-sm-start">
            <div className="d-flex align-items-center">
              <div className="logo-wrap">
                <Link to="/" className="navbar-brand" href="#">
                  <h1>
                    <img
                      src={"./logo.svg"}
                      className="img-fluid main-logo"
                      alt={siteTitle}
                    />
                  </h1>
                </Link>
              </div>
              <div className="first-column-text align-items">
                Development <br />
                Management
                <br />
                Investment
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex second-column-inner align-items-center justify-content-center justify-content-sm-start">
              <p>
                <a className="d-block" href="mailto:info@gothamsd.com">
                  info@gothamsd.com
                </a>
                <a className="d-block" href="tel:+16192311500">
                  619-231-1500
                </a>
                <a
                  className="d-block"
                  href="https://goo.gl/maps/fgkY5LHkWTqsHEfJ8"
                >
                  861 6th Ave #310 San Diego, CA 92101
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
                aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <ExactNavLink
                to="/"
              >
                Home
              </ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink
                to="/about"
              >
                About
              </ExactNavLink>
            </li>
          </ul>
        </div> */}
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
