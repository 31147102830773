import React from "react"
import { Link } from "gatsby"

import "@styles/footer.scss"

const Footer = () => {
  return (
    <div className="footer-custom bg-primary">
      <div className="container">
        <div className="d-md-flex">
          <div className="col-md-6 d-flex justify-content-center justify-content-center justify-content-sm-start">
            <div className="d-flex align-items-center">
              <div className="logo-wrap">
                <Link to="/" className="navbar-brand" href="#">
                  <img
                    src={"./logo-white.svg"}
                    className="img-fluid logo"
                    alt={"Gotham"}
                  />
                </Link>
              </div>
              <div className="first-column-text align-items">
                Development <br />
                Management
                <br />
                Investment
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex second-column-inner align-items-center justify-content-center justify-content-sm-start">
              <p>
                <em>For general inquiries contact:</em>
                <br />
                <br />
                <a className="d-block" href="mailto:info@gothamsd.com">
                  info@gothamsd.com
                </a>
                <a className="d-block" href="tel:+16192311500">
                  619-231-1500
                </a>
                <a
                  className="d-block"
                  href="https://goo.gl/maps/fgkY5LHkWTqsHEfJ8"
                >
                  861 6th Ave #310 San Diego, CA 92101
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
